import * as THREE from "three";
import cdt2d from "cdt2d";
import { CSS2DObject } from "three/addons/renderers/CSS2DRenderer.js";
import { toRaw } from "vue";
import API from "@/api/API";
import {
  MIDPOINT_COLOR,
  POINT_COLOR,
  SELECTED_POINT_COLOR,
  SELECTED_LINE_COLOR,
  BLACK,
  SOLAR_POINT_COLOR,
  RENDERING_ORDER,
} from "../constants";
import trashRed from "@/assets/model/trash_red.svg";

export const updateMidPointPosition = function (
  instancedMesh,
  index,
  startPoint,
  endPoint
) {
  const transformationMatrix = new THREE.Matrix4();
  instancedMesh.getMatrixAt(index, transformationMatrix);

  transformationMatrix.decompose(
    new THREE.Vector3(),
    new THREE.Quaternion(),
    new THREE.Vector3()
  );

  const newPosition = new THREE.Vector3().lerpVectors(
    startPoint,
    endPoint,
    0.5
  );

  transformationMatrix.setPosition(newPosition);
  instancedMesh.setMatrixAt(index, transformationMatrix);
  instancedMesh.instanceMatrix.needsUpdate = true;
  instancedMesh.computeBVH();
};

export const createNonReactivePoint = function (
  referencePoint,
  color = MIDPOINT_COLOR
) {
  const dotMaterial = new THREE.MeshBasicMaterial({
    color: color,
  });

  const tempPoint = new THREE.Mesh(this.dotGeometry, dotMaterial);
  tempPoint.position.x = referencePoint.x;
  tempPoint.position.y = referencePoint.y;
  tempPoint.position.z = referencePoint.z;

  return tempPoint;
};

export const createNonReactiveAreaPoint = function (
  referencePoint,
  color = MIDPOINT_COLOR
) {
  const pointGroup = new THREE.Group();
  pointGroup.position.copy(referencePoint);

  const dotMaterial = new THREE.MeshBasicMaterial({
    color: BLACK,
  });

  const wrapper = new THREE.Mesh(this.dotGeometry, dotMaterial);

  const innerDotMaterial = new THREE.MeshBasicMaterial({
    color: color,
  });
  const innerDot = new THREE.Mesh(this.dotGeometry, innerDotMaterial);

  pointGroup.add(wrapper);
  pointGroup.add(innerDot);

  return pointGroup;
};

export const checkForClosedArea = function (area) {
  let pointIntersects = this.raycaster.intersectObject(area.points[0]);

  if (pointIntersects.length > 0 || area.closeArea) return true;
  return false;
};

export const detectMeasurementAreaIntersection = function () {
  let intersects = this.raycaster.intersectObjects(
    this.measurementAreas.filter((area) => area.plane).map((area) => area.plane)
  );
  if (intersects.length > 0) return intersects[0].object;
  return false;
};

export const showMeasurementAreaTrash = function (object) {
  const area = this.measurementAreas.find(
    (area) => area.plane.uuid === object.uuid
  );
  const label = area.label;
  const secondColumn = label.element.children[1];
  secondColumn.style = "margin: auto; margin-left: 8px;";
};

export const hideMeasurementAreaTrash = function (object) {
  const area = this.measurementAreas.find(
    (area) => area.plane.uuid === object.uuid
  );
  const label = area.label;
  const secondColumn = label.element.children[1];
  secondColumn.style = "margin: auto; margin-left: 8px; display: none;";
};

export const removeDashedLine = function () {
  if (this.dashedMeasurementLine) {
    this.scene.remove(this.dashedMeasurementLine);
    this.dashedMeasurementLine.children.forEach((child) => {
      if (child.geometry) child.geometry.dispose();
      if (child.material) child.material.dispose();
    });
  }
};
export const checkForComplexArea = function (area) {
  const points = area.points;
  const triangleIndices = this.getTriangleIndices(
    points,
    this.getAxisDifferences(points.map((point) => point.position))
  );
  if (triangleIndices.length === 0) return true;
  return false;
};

export const selectMeasurementArea = function (event) {
  event.preventDefault();
  if (event.target.tagName !== "CANVAS") return;
  this.setMousePosition(event);

  const measurementArea = this.detectMeasurementAreaIntersection();

  this.selectAreaFromSidebar(measurementArea);
};

export const selectAreaFromSidebar = function (areaArgument) {
  const measurementArea = areaArgument;

  if (measurementArea) {
    this.measurementAreas.forEach((area) =>
      this.unselectMeasurementArea(area.plane)
    );
    this.selectedMeasurementArea = measurementArea;
    if (measurementArea.material.opacity !== 0)
      measurementArea.material.opacity = 0.7;
    this.showMeasurementAreaTrash(measurementArea);
  } else {
    if (this.selectedMeasurementArea) {
      if (this.selectedMeasurementArea.material.opacity !== 0)
        this.selectedMeasurementArea.material.opacity = 0.5;
      this.hideMeasurementAreaTrash(this.selectedMeasurementArea);
    }
  }
};

export const unselectMeasurementArea = function (area) {
  if (area) {
    area.material.opacity = 0.5;
    this.hideMeasurementAreaTrash(area);
  }
};

export const reAddMeasurementPoint = function () {
  document.removeEventListener("click", this.reAddMeasurementPoint, false);
  document.addEventListener("click", this.addMeasurementPoint, false);
};

export const addMeasurementPoint = function (event) {
  event.preventDefault();

  if (this.disableClick(event)) return;

  this.setMousePosition(event);

  let newFixedLine = null;
  let midPoint = null;
  let oldTempLine = null;
  let oldTempLabel = null;
  let oldFirstPoint = null;
  let lastArea = null;

  // replace dashed line with solid line
  if (
    this.measurementAreas.length > 0 &&
    !this.measurementAreas[this.measurementAreas.length - 1].closed
  ) {
    lastArea = this.measurementAreas[this.measurementAreas.length - 1];
    oldFirstPoint = lastArea.firstPoint;

    if (lastArea.tempLine) {
      oldTempLine = lastArea.tempLine;
      oldTempLabel = lastArea.tempLabel;
      const solidLinePoints = [
        lastArea.firstPoint.position,
        this.checkForClosedArea(lastArea)
          ? lastArea.points[0].position
          : lastArea.tempPoint.position,
      ];
      let solidLine = this.createReactiveThickLine(solidLinePoints, 4.0);

      this.removeDashedLine();

      this.scene.add(solidLine);

      midPoint = this.createReactiveMidPoint(
        lastArea.firstPoint,
        this.checkForClosedArea(lastArea)
          ? lastArea.points[0]
          : lastArea.tempPoint
      );

      lastArea.tempLine = null;
      if (lastArea.lines) {
        newFixedLine = {
          line: solidLine,
          firstPoint: lastArea.firstPoint,
          secondPoint: this.checkForClosedArea(lastArea)
            ? lastArea.points[0]
            : lastArea.tempPoint,
          label: lastArea.tempLabel,
          midPoint: midPoint,
        };
        lastArea.lines.push(newFixedLine);
      } else {
        newFixedLine = {
          line: solidLine,
          firstPoint: lastArea.firstPoint,
          secondPoint: this.checkForClosedArea(lastArea)
            ? lastArea.points[0]
            : lastArea.tempPoint,
          label: lastArea.tempLabel,
          midPoint: midPoint,
        };
        lastArea.lines = [newFixedLine];
      }
    }
  }
  // check for closed area
  if (
    this.measurementAreas.length > 0 &&
    this.measurementAreas[this.measurementAreas.length - 1]?.points.length >
      2 &&
    !this.measurementAreas[this.measurementAreas.length - 1].closed
  ) {
    if (
      this.checkForClosedArea(
        this.measurementAreas[this.measurementAreas.length - 1]
      )
    ) {
      this.measurementAreas[this.measurementAreas.length - 1].complex =
        this.checkForComplexArea(
          this.measurementAreas[this.measurementAreas.length - 1]
        );
      this.drawMeasurementAreaPlane(
        this.measurementAreas[this.measurementAreas.length - 1]?.points,
        true
      );
      // removed due to mk3 split release
      // this.areaMeasurementActivate();

      this.toggleActive(0);
      return;
    }
  }

  // check for duplicate points
  if (
    this.measurementAreas.length > 0 &&
    this.measurementAreas[this.measurementAreas.length - 1]?.points.length >
      0 &&
    !this.measurementAreas[this.measurementAreas.length - 1].closed
  ) {
    let pointIntersects = this.raycaster.intersectObjects(
      this.measurementAreas[this.measurementAreas.length - 1].points
    );
    if (pointIntersects.length > 0) return;
  }

  let intersects = this.raycaster.intersectObject(this.modelObject.children[0]);

  // mouse doesn't intersect the model
  if (intersects.length < 1) return;

  // disable point placement when drag is on
  if (this.dragOn) return;

  let o = intersects[0];
  let pIntersect = o.point.clone();
  this.scene.worldToLocal(pIntersect);

  const dot = this.createReactivePoint(
    pIntersect,
    POINT_COLOR,
    this.isFirstMeasurementPoint
  );

  const tempPoint = this.createNonReactivePoint(pIntersect);

  if (
    this.measurementAreas.length > 0 &&
    !this.measurementAreas[this.measurementAreas.length - 1].closed
  ) {
    this.measurementAreas[this.measurementAreas.length - 1].points.push(dot);
  } else {
    this.measurementAreas.push({
      points: [dot],
      closed: false,
    });
  }

  let measurementArea = this.measurementAreas[this.measurementAreas.length - 1];

  measurementArea.firstPoint = dot;
  measurementArea.tempPoint = tempPoint;

  if (
    measurementArea.lines &&
    measurementArea.lines[measurementArea.lines.length - 1].firstPoint
  ) {
    measurementArea.lines[measurementArea.lines.length - 1].secondPoint = dot;
  }
  this.camera.lookAt(measurementArea.firstPoint.position);

  const firstPoint = new THREE.Vector3();
  const secondPoint = new THREE.Vector3();
  measurementArea.firstPoint.getWorldPosition(firstPoint);
  measurementArea.tempPoint.getWorldPosition(secondPoint);
  let points = [firstPoint, secondPoint];

  let newLine = this.createReactiveThickLine(points, 4.0, true);
  this.dashedMeasurementLine = newLine;
  measurementArea.tempLine = newLine;

  let newLabel = this.createLabelBetweenTwoPoints(
    firstPoint,
    secondPoint,
    0.5,
    false,
    null
  );
  measurementArea.tempLabel = newLabel;

  this.scene.add(dot);
  const area = this.measurementAreas[this.measurementAreas.length - 1];
  const label =
    lastArea && lastArea.lines.length > 0 ? lastArea.tempLabel : null;
  const line = newFixedLine ? newFixedLine.line : null;

  if (area.points.length > 1)
    // add point undo callback function
    this.undoStack.push({
      action: "ADD_POINT",
      area: area,
      point: dot,
      line: line,
      midPoint: midPoint,
      oldTempLine: oldTempLine,
      oldFirstPoint: oldFirstPoint,
      newTempLine: newLine,
      oldTempLabel: oldTempLabel,
      newTempLabel: newLabel,
      fixedLine: newFixedLine,
    });
  this.resetRedoStack();
};

export const stickMousePointerToDotForMeasurementArea = function (event) {
  // disable point placing when clicking outside the model
  if (event.target.tagName !== "CANVAS") return;

  this.setMousePosition(event);

  if (
    this.measurementAreas.length === 0 ||
    this.measurementAreas[this.measurementAreas.length - 1].points.length ===
      0 ||
    this.measurementAreas[this.measurementAreas.length - 1].closed
  )
    return;

  let intersects = this.raycaster.intersectObject(this.modelObject.children[0]);
  if (intersects.length < 1) return;
  let o = intersects[0];
  let pIntersect = o.point.clone();
  this.scene.worldToLocal(pIntersect);

  const currentMeasurementArea =
    this.measurementAreas[this.measurementAreas.length - 1];

  const firstDot = currentMeasurementArea?.points[0];
  const secondDot = currentMeasurementArea?.points[1];
  const thirdDot = currentMeasurementArea?.points[2];
  const lastDot =
    currentMeasurementArea?.points[currentMeasurementArea.points.length - 1];

  const distance = firstDot.position.distanceTo(pIntersect);
  const threshold = 0.25;

  if (firstDot && secondDot && thirdDot) {
    if (distance <= threshold) {
      this.selectedPoint = firstDot;
      this.renderer.domElement.style.cursor = `none`;
      this.measurementAreas[this.measurementAreas.length - 1].closeArea = true;
      this.dashedMeasurementLine.material.color.setHex(SELECTED_POINT_COLOR);
      this.showSnapIcon();

      if (this.measurementAreaEndingLine)
        this.measurementAreaEndingLine.visible = false;
      this.inMagenticField = true;

      const firstPoint = new THREE.Vector3();
      const secondPoint = new THREE.Vector3();
      firstDot.getWorldPosition(firstPoint);
      lastDot.getWorldPosition(secondPoint);
      let points = [firstPoint, secondPoint];
      this.updateLinePosition(this.dashedMeasurementLine, points);
    } else {
      if (this.detectMeasurementAreaIntersection()) {
        this.renderer.domElement.style.cursor = `default`;
      } else {
        this.changeCursorToCrosshair();
      }
      this.measurementAreas[this.measurementAreas.length - 1].closeArea = false;
      if (this.selectedPoint) {
        this.dashedMeasurementLine.material.color.setHex(POINT_COLOR);
        if (this.measurementAreaEndingLine)
          this.measurementAreaEndingLine.visible = true;
        this.inMagenticField = false;
        this.hideSnapIcon();
        this.selectedPoint = null;
      }
    }
  } else {
    if (this.detectMeasurementAreaIntersection()) {
      this.renderer.domElement.style.cursor = `default`;
    } else {
      this.changeCursorToCrosshair();
    }
    if (this.selectedPoint) {
      this.dashedMeasurementLine.material.color.setHex(POINT_COLOR);
      if (this.measurementAreaEndingLine)
        this.measurementAreaEndingLine.visible = true;
      this.inMagenticField = false;
      this.hideSnapIcon();
      this.selectedPoint = null;
    }
  }
};

export const updatePreliminaryPointPositionForMeasurementArea = function (
  event
) {
  if (this.shouldThrottle()) return;

  let intersects = [];
  if (
    this.measurementAreas.length > 0 &&
    !this.measurementAreas[this.measurementAreas.length - 1].closed &&
    !this.inMagenticField
  ) {
    this.setMousePosition(event);
    intersects = this.raycaster.intersectObject(this.modelObject.children[0]);

    if (intersects.length < 1) return;
    let o = intersects[0];
    let pIntersect = o.point.clone();
    this.scene.worldToLocal(pIntersect);
    const measurementArea =
      this.measurementAreas[this.measurementAreas.length - 1];
    let marker = measurementArea.tempPoint;
    marker.position.x = pIntersect.x;
    marker.position.y = pIntersect.y;
    marker.position.z = pIntersect.z;
    const firstPoint = new THREE.Vector3();
    const secondPoint = new THREE.Vector3();
    measurementArea.firstPoint.getWorldPosition(firstPoint);
    measurementArea.tempPoint.getWorldPosition(secondPoint);
    let points = [firstPoint, secondPoint];

    if (this.dashedMeasurementLine) {
      this.updateLinePosition(this.dashedMeasurementLine, points);
    }

    const line = this.dashedMeasurementLine;
    const lineObject = this.scene.getObjectById(line.id);
    if (!lineObject) this.scene.add(toRaw(line));

    const label = measurementArea.tempLabel;
    const distance = this.updateLabelBetweenTwoPoints(
      label,
      firstPoint,
      secondPoint,
      0.5,
      true
    );

    const object = this.scene.getObjectById(label.id);
    if (!object && distance > 0.5) {
      this.scene.add(toRaw(label));
    }

    const numPoints = measurementArea.points.length;
    if (numPoints > 2) {
      const measurementAreaFirstDot = new THREE.Vector3();
      measurementArea.points[0].getWorldPosition(measurementAreaFirstDot);
      const endingPoints = [measurementAreaFirstDot, secondPoint];

      if (this.measurementAreaEndingLine) {
        this.updateLinePosition(this.measurementAreaEndingLine, endingPoints);
      } else {
        let newDottedLine = this.createReactiveThickLine(
          endingPoints,
          4.0,
          true,
          true
        );
        this.measurementAreaEndingLine = newDottedLine;
        this.scene.add(newDottedLine);
      }
    }
  }
  return intersects;
};

export const checkLabelsOverlapWithMidpoints = function (area) {
  for (let line of area.lines) {
    const overlaps = this.checkLabelOverlapWithPoint(
      line.label,
      line.midPoint.children[0]
    );
    console.log(overlaps);
  }
};

export const drawMeasurementAreaPlane = async function (
  points,
  createArea = true
) {
  const currentMeasurementArea =
    this.measurementAreas[this.measurementAreas.length - 1];

  this.resetUndoStack();
  this.resetRedoStack();
  this.removeSnapIcon(true);

  const instancedMesh = this.replacePointsWithInstancedMesh(points);
  this.scene.add(instancedMesh);

  const midpointInstancedMesh = this.replacePointsWithInstancedMesh(
    currentMeasurementArea.lines.map((line) => line.midPoint),
    POINT_COLOR,
    false,
    true
  );
  this.scene.add(midpointInstancedMesh);

  const vectorPoints = points.map(
    (point) =>
      new THREE.Vector3(point.position.x, point.position.y, point.position.z)
  );

  const pointsAsArray = vectorPoints.map((point) => [
    point.x,
    point.y,
    point.z,
  ]);

  const flatPoints = [].concat(...pointsAsArray);

  const geometry = new THREE.BufferGeometry();
  const vertices = new Float32Array(flatPoints);

  let indices;

  if (currentMeasurementArea.complex) {
    indices = this.generateIndices(vectorPoints);
  } else {
    const triangleIndices = this.getTriangleIndices(
      currentMeasurementArea.points,
      this.getAxisDifferences(
        currentMeasurementArea.points.map((point) => point.position)
      )
    );
    indices = [].concat(...triangleIndices);
  }

  geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3));
  geometry.setIndex(new THREE.Uint16BufferAttribute(indices, 1));

  const material = new THREE.MeshBasicMaterial({
    color: POINT_COLOR,
    side: THREE.DoubleSide,
    transparent: true,
    opacity: currentMeasurementArea.complex ? 0.0 : 0.5,
  });

  this.removeDashedLine();

  if (this.measurementAreaEndingLine)
    this.removeObjectFromScene(this.measurementAreaEndingLine);

  this.measurementAreaEndingLine = null;

  this.combineAreaLines(currentMeasurementArea);

  const plane = new THREE.Mesh(geometry, material);
  plane.material.depthTest = false;
  plane.renderOrder = RENDERING_ORDER.MEASUREMENT_PLANE;

  let surfaceArea = "";
  let weightedAngle = "";

  if (!currentMeasurementArea.complex) {
    ({ surfaceArea, weightedAngle } = this.calculateAreaMeasurement(points));
  }

  const areaParagraph = document.createElement("p");
  areaParagraph.textContent = `${surfaceArea}m²`;

  const angleParagraph = document.createElement("p");
  angleParagraph.textContent = `${weightedAngle}°`;

  const trashCan = document.createElement("img");

  trashCan.src = trashRed;
  trashCan.style = "cursor:pointer; width: 25px; pointer-events: all;";

  trashCan.addEventListener("click", this.removeSelectedMeasurementArea);

  const trashCanDiv = document.createElement("div");
  trashCanDiv.append(trashCan);

  const div = document.createElement("div");
  div.className = "areaLabel row";

  const firstColumn = document.createElement("div");
  const secondColumn = document.createElement("div");

  firstColumn.className = "column";
  firstColumn.append(areaParagraph);
  firstColumn.append(angleParagraph);

  secondColumn.className = "column";
  secondColumn.style = "margin: auto; margin-left: 8px; display: none;";
  secondColumn.append(trashCanDiv);

  div.appendChild(firstColumn);
  div.appendChild(secondColumn);

  let label = new CSS2DObject(div);
  const centerPoint = this.getCenterPointFromVectors(vectorPoints);
  label.position.set(centerPoint.x, centerPoint.y, centerPoint.z);

  label.layers.set(0);
  label.renderOrder = RENDERING_ORDER.MEASUREMENT_AREA_LABEL;
  this.scene.add(label);

  this.measurementAreas[this.measurementAreas.length - 1].plane = plane;
  this.measurementAreas[this.measurementAreas.length - 1].points =
    vectorPoints.map((point) => {
      return {
        position: point,
      };
    });
  this.measurementAreas[this.measurementAreas.length - 1].expanded = false;
  this.measurementAreas[this.measurementAreas.length - 1].panelSpacing = null;
  this.measurementAreas[
    this.measurementAreas.length - 1
  ].transparencyLevel = 100;
  this.measurementAreas[this.measurementAreas.length - 1].panelType = null;
  this.measurementAreas[this.measurementAreas.length - 1].panels = [];
  this.measurementAreas[this.measurementAreas.length - 1].closed = true;
  this.measurementAreas[
    this.measurementAreas.length - 1
  ].closemeasurementArea = false;
  this.measurementAreas[this.measurementAreas.length - 1].label = label;
  this.measurementAreas[this.measurementAreas.length - 1].indices = indices;
  this.measurementAreas[this.measurementAreas.length - 1].surfaceArea =
    surfaceArea;
  this.measurementAreas[this.measurementAreas.length - 1].angle = weightedAngle;
  this.measurementAreas[this.measurementAreas.length - 1].pointInstancedMesh =
    instancedMesh;
  this.measurementAreas[
    this.measurementAreas.length - 1
  ].midpointInstancedMesh = midpointInstancedMesh;
  this.scene.add(plane);

  const areaPoints = points.map((point) => {
    return {
      x: point.position.x,
      y: point.position.y,
      z: point.position.z,
    };
  });

  if (!this.anonymousUser && createArea) {
    try {
      const { data } = await this.createMeasurementAreaObject(areaPoints);
      this.measurementAreas[this.measurementAreas.length - 1].id = data;
    } catch (e) {}
  }
};

export const getTriangleIndices = function (points, trial = 0) {
  const delPoints = points.map((v) => {
    return [
      trial < 2 ? v.position.x : v.position.y,
      trial < 1 ? v.position.y : v.position.z,
    ];
  });
  let delEdges = [];

  for (var i = 0; i < points.length; i++) {
    const pair = [i, (i + 1) % points.length];
    delEdges.push(pair);
  }
  const triangleIndices = cdt2d(delPoints, delEdges, { exterior: false });
  return triangleIndices;
};

export const getAxisDifferences = function (vectors) {
  const minX = Math.min(...vectors.map((vector) => vector.x));
  const maxX = Math.max(...vectors.map((vector) => vector.x));
  const diffX = maxX - minX;

  const minY = Math.min(...vectors.map((vector) => vector.y));
  const maxY = Math.max(...vectors.map((vector) => vector.y));
  const diffY = maxY - minY;

  const minZ = Math.min(...vectors.map((vector) => vector.z));
  const maxZ = Math.max(...vectors.map((vector) => vector.z));
  const diffZ = maxZ - minZ;

  if (diffX === Math.min(diffX, diffY, diffZ)) {
    return 2;
  } else if (diffY === Math.min(diffX, diffY, diffZ)) {
    return 1;
  } else {
    return 0;
  }
};

export const calculateAreaMeasurement = function (points) {
  const triangleIndices = this.getTriangleIndices(
    points,
    this.getAxisDifferences(points.map((point) => point.position))
  );
  let triangles = [];
  for (let i = 0; i < triangleIndices.length; i++) {
    triangles.push([]);
    for (let j = 0; j < triangleIndices[i].length; j++) {
      triangles[i].push(points[triangleIndices[i][j]]);
    }
  }
  let area = 0;
  let angle = 0;
  for (let i = 0; i < triangles.length; i++) {
    const vertexA = triangles[i][0];
    const vertexB = triangles[i][1];
    const vertexC = triangles[i][2];

    // const triangleMesh = this.createTriangleMesh(vertexA, vertexB, vertexC);
    const triangleArea = this.calculateTriangleArea(
      vertexA.position,
      vertexB.position,
      vertexC.position
    );
    const triangleAngle = this.calculateTriangleAngle(
      vertexA.position,
      vertexB.position,
      vertexC.position
    );
    area = Number(area) + Number(triangleArea);
    angle = Number(angle) + Number(triangleAngle) * Number(triangleArea);
  }
  return {
    surfaceArea: area.toFixed(2),
    weightedAngle: (angle / area).toFixed(2),
  };
};

export const createTriangleMesh = function (vertexA, vertexB, vertexC) {
  var geometry = new THREE.BufferGeometry();

  // Create arrays to hold the vertex positions
  var vertices = new Float32Array([
    vertexA.position.x,
    vertexA.position.y,
    vertexA.position.z,
    vertexB.position.x,
    vertexB.position.y,
    vertexB.position.z,
    vertexC.position.x,
    vertexC.position.y,
    vertexC.position.z,
  ]);

  // Create a face using indices
  var indices = new Uint32Array([0, 1, 2]);

  // Add the vertices and indices to the BufferGeometry
  geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3));
  geometry.setIndex(new THREE.BufferAttribute(indices, 1));

  var color = new THREE.Color(Math.random(), Math.random(), Math.random());

  var material = new THREE.MeshBasicMaterial({
    color: color,
    side: THREE.DoubleSide,
  });

  return new THREE.Mesh(geometry, material);
};

export const calculateTriangleAngle = function (vertexA, vertexB, vertexC) {
  const vector1 = new THREE.Vector3().subVectors(vertexB, vertexA);
  const vector2 = new THREE.Vector3().subVectors(vertexC, vertexA);

  const normal = new THREE.Vector3().crossVectors(vector1, vector2).normalize();

  const verticalVector = new THREE.Vector3(0, -1, 0);

  const angle = verticalVector.angleTo(normal);

  let angleDegrees = THREE.MathUtils.radToDeg(angle);
  if (angleDegrees > 90) angleDegrees = 180 - angleDegrees;

  return angleDegrees.toFixed(2);
};

export const removeSelectedMeasurementArea = function () {
  let areaIndex, area;
  for (let i = 0; i < this.measurementAreas.length; i++) {
    let areaObj = this.measurementAreas[i];
    if (areaObj.plane.uuid === this.selectedMeasurementArea.uuid) {
      area = areaObj;
      areaIndex = i;
    }
  }

  const areaPositions = area.points.map((point) => {
    return {
      x: point.position.x,
      y: point.position.y,
      z: point.position.z,
    };
  });

  this.removeObjectFromScene(this.selectedMeasurementArea);
  this.removeObjectFromScene(area.label);

  for (let point of area.points) {
    this.removeObjectFromScene(point);
  }

  for (let line of area.lines) {
    this.removeObjectFromScene(line.label);
    this.removeObjectFromScene(line.midPoint);
  }

  this.removeObjectFromScene(area.combinedLine);

  this.removeObjectFromScene(area.pointInstancedMesh);
  this.removeObjectFromScene(area.midpointInstancedMesh);

  this.measurementAreas.splice(areaIndex, 1);
  this.deleteMeasurementAreaObject(area.id);

  this.undoStack.push({
    action: "DELETE_MEASUREMENT_AREA",
    area: {
      position: areaPositions,
    },
  });
  this.resetRedoStack();

  this.selectedMeasurementArea = null;
};

export const hideAllMeasurementAreas = function () {
  this.measurementAreas.forEach((area) => {
    hideSingleMeasurementArea(area);
  });
};

export const showAllMeasurementAreas = function () {
  this.measurementAreas.forEach((area) => {
    if (area.show) {
      showSingleMeasurementArea(area);
    }
  });
};
export const hideSingleMeasurementArea = function (area) {
  // Hide the main plane
  if (area.plane) {
    area.plane.visible = false;
  }

  // Hide the label
  if (area.label) {
    area.label.visible = false;
  }

  // Hide all points
  area.points.forEach((point) => {
    point.visible = false;
  });

  // Hide all lines and their labels
  area.lines.forEach((line) => {
    if (line.line) {
      line.line.visible = false;
    }
    if (line.label) {
      line.label.visible = false;
    }
    if (line.midPoint) {
      line.midPoint.visible = false;
    }
  });
};
export const showSingleMeasurementArea = function (area) {
  // Show the main plane
  if (area.plane) {
    area.plane.visible = true;
  }

  // Show the label
  if (area.label) {
    area.label.visible = true;
  }

  // Show all points
  area.points.forEach((point) => {
    point.visible = true;
  });

  // Show all lines and their labels
  area.lines.forEach((line) => {
    if (line.line) {
      line.line.visible = true;
    }
    if (line.label) {
      line.label.visible = true;
    }
    if (line.midPoint) {
      line.midPoint.visible = true;
    }
  });
};

export const enableMeasurementPointDragMode = function () {
  document.addEventListener("mousedown", this.dragMeasurementAreaPointStart);
};

export const disableMeasurementPointDragMode = function () {
  document.removeEventListener("mousedown", this.dragMeasurementAreaPointStart);
  document.removeEventListener("mousemove", this.dragMeasurementAreaPoint);
  document.removeEventListener("mouseup", this.dragMeasurementAreaPointEnd);
};

export const dragMeasurementAreaPointStart = function (e) {
  this.setMousePosition(e);
  const points = this.measurementAreas.map((area) => area.pointInstancedMesh);
  const midpoints = this.measurementAreas.map(
    (area) => area.midpointInstancedMesh
  );

  const pointIntersects = this.raycaster.intersectObjects(points, true);

  const midpointIntersects = this.raycaster.intersectObjects(midpoints, true);
  if (pointIntersects.length === 0 && midpointIntersects.length === 0) return;

  let isMidpoint = false;
  let intersectionPoint;

  if (pointIntersects.length > 0) {
    intersectionPoint = pointIntersects[0];
  } else {
    intersectionPoint = midpointIntersects[0];
    isMidpoint = true;
  }

  this.draggedAreaPoint = {
    area: this.measurementAreas.find((area) =>
      isMidpoint
        ? area.midpointInstancedMesh.id === intersectionPoint.object.id
        : area.pointInstancedMesh.id === intersectionPoint.object.id
    ),
    index: intersectionPoint.instanceId,
    isMidpoint,
  };

  const originalPosition = new THREE.Vector3();
  const transformationMatrix = new THREE.Matrix4();

  if (isMidpoint) {
    this.draggedAreaPoint.area.midpointInstancedMesh.getMatrixAt(
      this.draggedAreaPoint.index,
      transformationMatrix
    );
    this.draggedAreaPoint.area.midPointAdded = false;
  } else {
    this.draggedAreaPoint.area.pointInstancedMesh.getMatrixAt(
      this.draggedAreaPoint.index,
      transformationMatrix
    );
  }

  transformationMatrix.decompose(
    originalPosition,
    new THREE.Quaternion(),
    new THREE.Vector3()
  );

  this.draggedAreaPoint.lastPosition = {
    x: originalPosition.x,
    y: originalPosition.y,
    z: originalPosition.z,
  };

  this.draggedAreaPoint.originalPosition = {
    x: originalPosition.x,
    y: originalPosition.y,
    z: originalPosition.z,
  };

  this.cleanNavigationSetup();
  this.disableDefaultNavigation();

  this.unselectMeasurementArea(this.selectedMeasurementArea);

  document.addEventListener("mousemove", this.dragMeasurementAreaPoint);
  document.addEventListener("mouseup", this.dragMeasurementAreaPointEnd);

  this.dragOn = true;
};

export const dragMeasurementAreaPoint = function (e) {
  if (!this.dragOn) return;

  if (
    this.draggedAreaPoint.isMidpoint &&
    !this.draggedAreaPoint.area.midPointAdded
  ) {
    return this.dragMeasurementAreaMidPoint(e);
  }

  this.setMousePosition(e);

  const intersects = this.raycaster.intersectObject(
    this.modelObject.children[0]
  );

  const area = this.draggedAreaPoint.area;
  const instancedMesh = area.pointInstancedMesh;
  const pointIndex = this.draggedAreaPoint.index;

  if (intersects.length > 0) {
    const transformationMatrix = new THREE.Matrix4();
    const newPosition = new THREE.Vector3();

    instancedMesh.getMatrixAt(pointIndex, transformationMatrix);

    transformationMatrix.decompose(
      newPosition,
      new THREE.Quaternion(),
      new THREE.Vector3()
    );

    newPosition.set(
      intersects[0].point.x,
      intersects[0].point.y,
      intersects[0].point.z
    );

    transformationMatrix.setPosition(newPosition);
    instancedMesh.setMatrixAt(pointIndex, transformationMatrix);

    instancedMesh.instanceMatrix.needsUpdate = true;
    instancedMesh.computeBVH();
    area.midpointInstancedMesh.computeBVH();

    area.points[pointIndex].position = newPosition;
  }

  const pointsMerged = this.checkMergePoints(pointIndex, area);
  this.reDrawMeasurementAreaFromPoint(pointsMerged);

  this.draggedAreaPoint.pointsMerged = pointsMerged;
};

export const dragMeasurementAreaMidPoint = function (e) {
  this.setMousePosition(e);

  const intersects = this.raycaster.intersectObject(
    this.modelObject.children[0]
  );

  const area = this.draggedAreaPoint.area;
  const instancedMesh = area.midpointInstancedMesh;
  const pointIndex = this.draggedAreaPoint.index;

  if (intersects.length > 0) {
    const transformationMatrix = new THREE.Matrix4();
    const newPosition = new THREE.Vector3();

    instancedMesh.getMatrixAt(pointIndex, transformationMatrix);

    transformationMatrix.decompose(
      newPosition,
      new THREE.Quaternion(),
      new THREE.Vector3()
    );

    newPosition.set(
      intersects[0].point.x,
      intersects[0].point.y,
      intersects[0].point.z
    );

    transformationMatrix.setPosition(newPosition);
    instancedMesh.setMatrixAt(pointIndex, transformationMatrix);

    instancedMesh.instanceMatrix.needsUpdate = true;
    instancedMesh.computeBVH();
    area.pointInstancedMesh.computeBVH();

    if (area.lines[pointIndex].midPoint.children.length > 0) {
      this.updatePositionOnObject(
        area.lines[pointIndex].midPoint.children[0],
        newPosition
      );
      this.updatePositionOnObject(
        area.lines[pointIndex].midPoint.children[1],
        newPosition
      );
    } else {
      this.updatePositionOnObject(area.lines[pointIndex].midPoint, newPosition);
    }
  }

  this.draggedAreaPoint.intersectionPoint = intersects[0].point;

  const line = area.lines[pointIndex];

  this.hideObjectFromScene(line.label);

  area.points.splice(pointIndex + 1, 0, {
    position: intersects[0].point,
  });

  area.midPointAdded = true;

  // replace points with instanced mesh
  const pointInstancedMesh = this.replacePointsWithInstancedMesh(
    area.points,
    POINT_COLOR,
    area.pointInstancedMesh
  );
  this.scene.add(pointInstancedMesh);
  area.pointInstancedMesh = pointInstancedMesh;

  // replace midpoints with instanced mesh
  for (let line of area.lines) {
    this.removeObjectFromScene(line.midPoint);
    this.removeObjectFromScene(line.label);
  }
  this.reCreateLinesFromPoints(area);

  const midpointInstancedMesh = this.replacePointsWithInstancedMesh(
    area.lines.map((line) => line.midPoint),
    POINT_COLOR,
    area.midpointInstancedMesh,
    true
  );
  this.scene.add(midpointInstancedMesh);
  area.midpointInstancedMesh = midpointInstancedMesh;

  this.updateClosedLinePosition({
    line: area.combinedLine,
    points: area.points,
    forceUpdate: true,
  });

  this.draggedAreaPoint.index = pointIndex + 1;
};

export const updatePositionOnObject = function (object, position) {
  object.position.x = position.x;
  object.position.y = position.y;
  object.position.z = position.z;
};

export const createLineGroup = function (
  firstPoint,
  secondPoint,
  withLabel = true,
  withOffset = false,
  selected = false,
  skipLine = false
) {
  const solidLinePoints = [firstPoint.position, secondPoint.position];
  let solidLine;

  if (!skipLine) {
    solidLine = this.createReactiveThickLine(
      solidLinePoints,
      4.0,
      false,
      false,
      withLabel
        ? MIDPOINT_COLOR
        : selected
        ? SELECTED_LINE_COLOR
        : SOLAR_POINT_COLOR
    );
    this.scene.add(solidLine);
  }

  const midPoint = this.createReactiveMidPoint(
    firstPoint,
    secondPoint,
    withLabel
      ? MIDPOINT_COLOR
      : selected
      ? SELECTED_LINE_COLOR
      : SOLAR_POINT_COLOR
  );

  let label = undefined;
  if (withLabel) {
    label = this.createLabelBetweenTwoPoints(
      firstPoint.position,
      secondPoint.position,
      0.5,
      false,
      null,
      withOffset
    );
    this.scene.add(label);
  }

  return {
    line: solidLine,
    firstPoint: firstPoint,
    secondPoint: secondPoint,
    label: label,
    midPoint: midPoint,
  };
};

export const reDrawMeasurementAreaFromPoint = function (mergedPoints = false) {
  let currentMeasurementArea = this.draggedAreaPoint.area;
  this.selectedMeasurementArea = currentMeasurementArea.plane;
  const pointIndex = this.draggedAreaPoint.index;

  if (!currentMeasurementArea) return;

  currentMeasurementArea.complex = this.checkForComplexArea(
    currentMeasurementArea
  );

  const vectorPoints = currentMeasurementArea.points.map(
    (point) =>
      new THREE.Vector3(point.position.x, point.position.y, point.position.z)
  );

  const pointsAsArray = vectorPoints.map((point) => [
    point.x,
    point.y,
    point.z,
  ]);

  const flatPoints = [].concat(...pointsAsArray);

  const geometry = currentMeasurementArea.plane.geometry;
  const vertices = new Float32Array(flatPoints);

  let indices;

  if (currentMeasurementArea.complex) {
    indices = this.generateIndices(vectorPoints);
  } else {
    const triangleIndices = this.getTriangleIndices(
      currentMeasurementArea.points,
      this.getAxisDifferences(
        currentMeasurementArea.points.map((point) => point.position)
      )
    );
    indices = [].concat(...triangleIndices);
  }

  geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3));
  geometry.setIndex(new THREE.Uint16BufferAttribute(indices, 1));

  geometry.computeBoundingBox();

  this.updateClosedLinePosition({
    line: currentMeasurementArea.combinedLine,
    points: currentMeasurementArea.points,
  });

  if (!mergedPoints) {
    const firstLineIndex =
      pointIndex - 1 < 0
        ? currentMeasurementArea.lines.length - 1
        : pointIndex - 1;
    const secondLineIndex = pointIndex;
    const newPoint = currentMeasurementArea.points[pointIndex];

    currentMeasurementArea.lines[firstLineIndex].secondPoint = newPoint;
    currentMeasurementArea.lines[secondLineIndex].firstPoint = newPoint;

    const linesToUpdate = [
      {
        line: currentMeasurementArea.lines[firstLineIndex],
        index: firstLineIndex,
      },
      {
        line: currentMeasurementArea.lines[secondLineIndex],
        index: secondLineIndex,
      },
    ];

    for (let lineToUpdate of linesToUpdate) {
      const line = lineToUpdate.line;
      const index = lineToUpdate.index;
      const label = line.label;
      this.updateLabelBetweenTwoPoints(
        label,
        line.firstPoint.position,
        line.secondPoint.position,
        0.5,
        true
      );

      this.updateMidPointPosition(
        currentMeasurementArea.midpointInstancedMesh,
        index,
        line.firstPoint.position,
        line.secondPoint.position
      );
    }

    // snap dragged point to the mesh
    const cameraPosition = this.camera.position.clone();
    const pointPosition = newPoint.position.clone();
    const rayDirection = pointPosition.sub(cameraPosition).normalize();

    this.raycaster.set(cameraPosition, rayDirection);

    const intersects = this.raycaster.intersectObject(
      this.modelObject.children[0]
    );

    if (intersects.length > 0) {
      this.draggedAreaPoint.prevPosition = {
        x: newPoint.position.x,
        y: newPoint.position.y,
        z: newPoint.position.z,
      };
    }
  }

  // recalculate area measurments
  let surfaceArea = "";
  let weightedAngle = "";

  if (!currentMeasurementArea.complex) {
    ({ surfaceArea, weightedAngle } = this.calculateAreaMeasurement(
      currentMeasurementArea.points
    ));
  }
  currentMeasurementArea.surfaceArea = surfaceArea;
  currentMeasurementArea.angle = weightedAngle;

  currentMeasurementArea.surfaceArea = surfaceArea;
  currentMeasurementArea.angle = weightedAngle;

  const label = currentMeasurementArea.label;
  const centerPoint = this.getCenterPointFromVectors(vectorPoints);
  label.position.set(centerPoint.x, centerPoint.y, centerPoint.z);
  const firstColumn = label.element.children[0];
  const [areaParagraph, angleParagraph] = firstColumn.children;
  areaParagraph.textContent = `${surfaceArea}m²`;
  angleParagraph.textContent = `${weightedAngle}°`;

  return currentMeasurementArea;
};

export const dragMeasurementAreaPointEnd = function () {
  const { index, prevPosition, area } = this.draggedAreaPoint;

  if (!this.draggedAreaPoint.mergedPoints) {
    const originalPosition = new THREE.Vector3();
    const transformationMatrix = new THREE.Matrix4();

    area.pointInstancedMesh.getMatrixAt(index, transformationMatrix);

    transformationMatrix.decompose(
      originalPosition,
      new THREE.Quaternion(),
      new THREE.Vector3()
    );

    const cameraPosition = this.camera.position.clone();
    const pointPosition = originalPosition.clone();
    const rayDirection = pointPosition.sub(cameraPosition).normalize();

    this.raycaster.set(cameraPosition, rayDirection);

    const intersects = this.raycaster.intersectObject(
      this.modelObject.children[0]
    );

    if (intersects.length < 1 && prevPosition) {
      point.position.x = prevPosition.x;
      point.position.y = prevPosition.y;
      point.position.z = prevPosition.z;

      this.reDrawMeasurementAreaFromPoint();
    }

    if (area.midPointAdded) {
      this.undoStack.push({
        action: "MOVE_MID_POINT",
        areaType: "MEASUREMENT_AREA",
        area,
        index,
        isMeasurement: true,
      });
    } else {
      this.undoStack.push({
        action: "MOVE_POINT",
        areaType: "MEASUREMENT_AREA",
        area,
        index,
        position: this.draggedAreaPoint.originalPosition,
        isMeasurement: true,
      });
    }
    this.resetRedoStack();
  }

  document.removeEventListener("mousemove", this.dragMeasurementAreaPoint);
  document.removeEventListener("mouseup", this.dragMeasurementAreaPointEnd);
  document.addEventListener("click", this.selectMeasurementArea, false);

  this.updateMeasurementAreaObject(
    area.id,
    area.points.map((point) => {
      return {
        x: point.position.x,
        y: point.position.y,
        z: point.position.z,
      };
    })
  );

  this.restoreDefaultNavigation();

  this.draggedAreaPoint = null;
  this.dragOn = false;
};

export const createMeasurementAreaObject = async function (points) {
  if (this.sample) return;
  const areaObject = {
    projectId: Number(this.projectId),
    position: points,
  };
  return await API.airteam3DViewer.createMeasurementAreaObject(areaObject);
};

export const updateMeasurementAreaObject = async function (id, points) {
  if (this.sample) return;
  const areaObject = {
    id,
    projectId: Number(this.projectId),
    position: points,
  };
  return await API.airteam3DViewer.updateMeasurementAreaObject(areaObject);
};

export const deleteMeasurementAreaObject = async function (id) {
  if (this.sample) return;
  return await API.airteam3DViewer.deleteObject(id);
};

export const removeUnfinishedAreaMeasurements = function () {
  const currentAreaMeasurement =
    this.measurementAreas[this.measurementAreas.length - 1];
  if (!currentAreaMeasurement || currentAreaMeasurement.closed) return;
  if (this.measurementAreaEndingLine) {
    this.removeObjectFromScene(this.measurementAreaEndingLine);
    this.measurementAreaEndingLine = null;
  }
  this.removeDashedLine();
  const tempLabel = this.scene.getObjectById(
    currentAreaMeasurement.tempLabel.id
  );
  this.scene.remove(tempLabel);

  this.removeSnapIcon(true);
  if (currentAreaMeasurement.points)
    this.removeArrayFromScene(currentAreaMeasurement.points);

  if (currentAreaMeasurement.lines) {
    this.removeArrayFromScene(
      currentAreaMeasurement.lines.map((line) => line.label)
    );
    this.removeArrayFromScene(
      currentAreaMeasurement.lines.map((line) => line.line)
    );
    currentAreaMeasurement.lines
      .map((line) => line.midPoint)
      .forEach((point) => {
        this.removeObjectWithChildrenFromScene(point);
      });
  }

  this.measurementAreas.pop();
};

export const removeLastMeasurementPoint = function (event) {
  const key = event.key;
  if (key !== "Backspace" && key !== "Delete") return;

  if (this.measurementAreas.length === 0) return;
  const currentArea = this.measurementAreas[this.measurementAreas.length - 1];
  if (currentArea.points.length === 0 || currentArea.closed) return;
  if (currentArea.points.length === 1) {
    this.removeUnfinishedAreaMeasurements();
  } else {
    this.undo();
  }
};
