import { decodeJwt } from "@/utils/decode-jwt.js";

const state = {
  token: null,
  isSample: false,
  isThermal: false,
  isAnonymous: false,
  projectId: null,
};

const getters = {
  token: (state) => state.token,
  decodedToken: (state) => state.decodedToken,
  isSample: (state) => state.isSample,
  isAnonymous: (state) => state.isAnonymous,
  projectId: (state) => state.projectId,
};

const actions = {};

const mutations = {
  setToken(state, token) {
    state.token = token;
    const decodedToken = decodeJwt(token);
    state.decodedToken = decodedToken;
  },
  resetTokent(state) {
    state.token = null;
    state.decodedToken = null;
  },
  setIsSample(state, value) {
    state.isSample = value;
  },
  setIsThermal(state, value) {
    state.setIsThermal = value;
  },
  setIsAnonymous(state, value) {
    state.isAnonymous = value;
  },
  setProjectId(state, value) {
    state.projectId = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
