import { createRouter, createWebHistory } from "vue-router";
import AirteamViewer from "../components/AirteamViewer.vue";

const routes = [
  {
    path: "/",
    component: AirteamViewer,
  },
  {
    path: "/sample",
    component: AirteamViewer,
    props: { sample: true, thermal: false },
  },
  {
    path: "/thermal",
    component: AirteamViewer,
    props: { sample: true, thermal: true },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
