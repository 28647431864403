import API from "@/api/API";
import { VERTICAL, HORIZONTAL } from "../../constants";

const state = {
  customPanels: [],
};

const getters = {
  customPanels: (state) => state.customPanels,
};

const actions = {
  async getCustomPanels({ commit }, { projectId } = { projectId: null }) {
    try {
      const res = await API.airteam3DViewer.getCustomPanels(projectId);
      const customPanels = [];

      res.data.forEach((panel) => {
        const originalPanel = {
          ...panel,
          texture: null,
          textureId: panel.textureId ? panel.textureId : "90",
          size: {
            width: panel.size.width / 1000,
            height: panel.size.height / 1000,
          },
          orientation:
            panel.size.height > panel.size.width ? VERTICAL : HORIZONTAL,
          size: {
            width: panel.size.width / 1000,
            height: panel.size.height / 1000,
          },
        };

        const flippedPanel = {
          ...originalPanel,
          size: {
            height: originalPanel.size.width,
            width: originalPanel.size.height,
          },
          orientation:
            originalPanel.orientation === VERTICAL ? HORIZONTAL : VERTICAL,
        };

        customPanels.push(originalPanel, flippedPanel);
      });

      commit("setCustomPanels", customPanels);
    } catch (error) {
      console.error("Failed to fetch custom panels:", error);
    }
  },
  setNewCustomPanels({ commit }, customPanels) {
    commit("setCustomPanels", customPanels);
  },
};

const mutations = {
  setCustomPanels(state, customPanels) {
    state.customPanels = customPanels;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
